import React from "react"
import YAMLGlobal from "../content/global.yaml"
import YAMLContent from "../content/aboutPage.yaml"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import Partners from "../components/AboutPage/Partners/Partners"
import BlockPress from "../components/AboutPage/InThePress/InThePress"
import AboutZionodes from "../components/AboutPage/AboutZionodes/AboutZionodes"

import { graphql, useStaticQuery } from "gatsby"
import LazyHydrate from "react-lazy-hydration"

const AboutPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      about: directusPartnersEng {
        seo_title
        seo_description
        seo_keywords
        seo_image {
          localFile {
            childImageSharp {
              fixed(quality: 90, fit: COVER, height: 630, width: 1200) {
                src
              }
            }
          }
        }
        first_screen_zionodes_titlee
        first_screen_zionodes_description
        first_screen_mission_title
        first_screen_mission_description
        first_screen_vision_title
        first_screen_vision_description
        first_screen_founing_membs {
          id
          name
          description
          link
          image_style
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        parts {
          id
          title
          link
          icon {
            localFile {
              publicURL
            }
          }
          icon_active {
            localFile {
              publicURL
            }
          }
        }
      }
      blog: allDirectusBlogEng(
        sort: { fields: created_on, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            id
            category
            created_on(formatString: "MMM DD, YYYY")
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            title
            description
            slug
          }
        }
      }
    }
  `)

  const seo = {
    title: data.about.seo_title,
    description: data.about.seo_description,
    keywords: data.about.seo_keywords,
    image: data.about.seo_image.localFile.childImageSharp.fixed.src,
  }

  const about = {
    zionodes: {
      title: data.about.first_screen_zionodes_title,
      description: data.about.first_screen_zionodes_description,
    },
    mission: {
      title: data.about.first_screen_mission_title,
      description: data.about.first_screen_mission_description,
    },
    vision: {
      title: data.about.first_screen_vision_title,
      description: data.about.first_screen_vision_description,
    },
    members: data.about.first_screen_founing_membs,
  }

  const partners = data.about.parts
  const blog = data.blog.edges

  return (
    <Layout location={location}>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        image={seo.image}
      />

      <AboutZionodes
        data={about}
        header={YAMLContent.firstScreen.header}
        tabs={YAMLContent.firstScreen.tabs}
      />

      <BlockPress
        header={YAMLContent.press.header}
        button={YAMLGlobal.readMore}
        noCategory={YAMLContent.press.noCategory}
        data={blog}
        withButton
        viewMore={YAMLGlobal.viewMore}
      />

      <LazyHydrate ssrOnly>
        <Partners data={partners} header={YAMLContent.partners.header} />
      </LazyHydrate>
    </Layout>
  )
}

export default AboutPage
