// extracted by mini-css-extract-plugin
export var animation = "InThePressSkeleton-module--animation--SDVI2";
export var button = "InThePressSkeleton-module--button--Lp3Fj";
export var card = "InThePressSkeleton-module--card---2wJS";
export var cards = "InThePressSkeleton-module--cards--OmrkC";
export var cardsBig = "InThePressSkeleton-module--cardsBig--PftYp";
export var category = "InThePressSkeleton-module--category--470zT";
export var content = "InThePressSkeleton-module--content--Io0ep";
export var contentBlock = "InThePressSkeleton-module--contentBlock--vDLwi";
export var date = "InThePressSkeleton-module--date--86l9C";
export var describe = "InThePressSkeleton-module--describe--Io6A9";
export var headerRow = "InThePressSkeleton-module--headerRow--dXEdO";
export var icon = "InThePressSkeleton-module--icon--Zl3sx";
export var iconImage = "InThePressSkeleton-module--iconImage--wbe2c";
export var image = "InThePressSkeleton-module--image--4KBf6";
export var inner = "InThePressSkeleton-module--inner--PB5tk";
export var link = "InThePressSkeleton-module--link--OT5Vj";
export var load = "InThePressSkeleton-module--load--d7dRH";
export var nameAndData = "InThePressSkeleton-module--nameAndData---VjY+";
export var noCategory = "InThePressSkeleton-module--noCategory--gPOkd";
export var placeholder = "InThePressSkeleton-module--placeholder--0D-YJ";
export var placeholderInner = "InThePressSkeleton-module--placeholderInner--z2u0B";
export var searchButton = "InThePressSkeleton-module--searchButton--KsW8P";
export var searchInput = "InThePressSkeleton-module--searchInput--mcL4L";
export var searchRow = "InThePressSkeleton-module--searchRow--hUesc";
export var section = "InThePressSkeleton-module--section--1std-";