import React from "react"

// Components
import Container from "../../../ui-kit/Container/Container"
import Row from "../../../ui-kit/Row/Row"
import StyledCard from "../../../ui-kit/StyledCard/StyledCard"
import Heading from "../../../ui-kit/Heading/Heading"
import Section from "../../../ui-kit/Section/Section"

// Styles
import * as styles from "./Partners.module.sass"

const Partners = ({ data, header }) => {
  return (
    <Section>
      <Container>
        <Row>
          <Heading theme={"left"}>
            <h2>{header}</h2>
          </Heading>
          <div className={styles.blockCard}>
            {data &&
              data.map(partner => (
                <div key={partner.id} className={styles.card}>
                  <StyledCard theme={"stable"} link={partner.link} external>
                    <div className={styles.inner} title={partner.title}>
                      <div className={styles.icon}>
                        <div className={styles.iconDefault}>
                          <img
                            src={partner.icon.localFile.publicURL}
                            alt={partner.title}
                          />
                        </div>
                        <div className={styles.iconActive}>
                          <img
                            src={partner.icon_active.localFile.publicURL}
                            alt={partner.title}
                          />
                        </div>
                      </div>
                    </div>
                  </StyledCard>
                </div>
              ))}
          </div>
        </Row>
      </Container>
    </Section>
  )
}

export default Partners
