// extracted by mini-css-extract-plugin
export var active = "AboutZionodes-module--active--kn1uV";
export var backgroundIcon = "AboutZionodes-module--backgroundIcon--dGflP";
export var border = "AboutZionodes-module--border--VFNud";
export var button = "AboutZionodes-module--button--QLEgZ";
export var card = "AboutZionodes-module--card--X5W9e";
export var cardInner = "AboutZionodes-module--cardInner--FN6+e";
export var cards = "AboutZionodes-module--cards--I4I3W";
export var content = "AboutZionodes-module--content--JLDB-";
export var icon = "AboutZionodes-module--icon--u1Uim";
export var image = "AboutZionodes-module--image--0asVE";
export var imageInner = "AboutZionodes-module--imageInner--JCtN4";
export var item = "AboutZionodes-module--item--+cgyI";
export var reverse = "AboutZionodes-module--reverse--fGata";
export var tabs = "AboutZionodes-module--tabs--bVbMd";
export var tabsItem = "AboutZionodes-module--tabsItem--seXn0";
export var tabsList = "AboutZionodes-module--tabsList--2xXPE";
export var tabsListInner = "AboutZionodes-module--tabsListInner--9tOHF";
export var tabsListOuter = "AboutZionodes-module--tabsListOuter--U2nBW";
export var tabsListWrapper = "AboutZionodes-module--tabsListWrapper--nnSMO";