import React, { useState } from "react"
import classNames from "classnames"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { AnimateSharedLayout, motion } from "framer-motion"
import Img from "gatsby-image"

// Components
import Container from "../../../ui-kit/Container/Container"
import Row from "../../../ui-kit/Row/Row"
import StyledCard from "../../../ui-kit/StyledCard/StyledCard"
import Button from "../../../ui-kit/Button/Button"

// Icons
import MaskIcon from "../../../icons/light/testimonials/Substract2.svg"
import Mask2Icon from "../../../icons/light/testimonials/Subtract.svg"

import MaskIconDark from "../../../icons/dark/testimonials/Substract2.svg"
import Mask2IconDark from "../../../icons/dark/testimonials/Subtract.svg"

import LinkedInIcon from "../../../icons/light/aboutzinodes/linkedin.svg"
import LinkedInIconDark from "../../../icons/dark/aboutzinodes/linkedin.svg"

// Styles
import * as styles from "./AboutZionodes.module.sass"
import LazyHydrate from "react-lazy-hydration"
import { useCurrentTheme } from "../../../utils/themeChanger"

const AboutZionodes = ({ data, header, tabs }) => {
  const [selectedIcon, setSelectedIcon] = useState(0)

  const colorTheme = useCurrentTheme()
  const isLightTheme = colorTheme.data?.theme === "light"

  return (
    <div className={styles.content}>
      <Container>
        <Row theme="nowrap flex-start">
          <div className={styles.item}>
            <LazyHydrate ssrOnly>
              <h1 dangerouslySetInnerHTML={{ __html: header }} />
            </LazyHydrate>
            <LazyHydrate whenIdle>
              <Tabs
                className={styles.tabs}
                onSelect={index =>
                  selectedIcon !== index && setSelectedIcon(index)
                }
              >
                <div className={styles.tabsListOuter}>
                  <div className={styles.tabsListWrapper}>
                    <TabList className={styles.tabsList}>
                      <div className={styles.tabsListInner}>
                        <AnimateSharedLayout>
                          {tabs.map(el => (
                            <Tab
                              className={styles.tabsItem}
                              selectedClassName={styles.active}
                              key={el}
                            >
                              {el}
                              {tabs[selectedIcon] === el && (
                                <motion.div
                                  transition={spring}
                                  layoutId="outline"
                                  initial={false}
                                  className={styles.border}
                                />
                              )}
                            </Tab>
                          ))}
                        </AnimateSharedLayout>
                      </div>
                    </TabList>
                  </div>
                </div>

                <TabPanel>
                  <h3>{data.zionodes.title}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.zionodes.description,
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <h3>{data.mission.title}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.mission.description,
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <h3>{data.vision.title}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.vision.description,
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <div className={styles.cards}>
                    {data.members.map(member => (
                      <div className={styles.card}>
                        <StyledCard>
                          <div className={styles.cardInner}>
                            <div className={styles.image}>
                              <div
                                className={classNames(styles.imageInner, {
                                  [styles.reverse]:
                                    member.image_style === "angle_top",
                                })}
                              >
                                <Img
                                  fluid={
                                    member.image.localFile.childImageSharp.fluid
                                  }
                                  alt={member.name}
                                />
                              </div>
                              <div className={styles.backgroundIcon}>
                                {member.image_style === "angle_top" ? (
                                  <img
                                    src={isLightTheme ? MaskIcon : MaskIconDark}
                                    alt="mask"
                                  />
                                ) : (
                                  <img
                                    src={
                                      isLightTheme ? Mask2Icon : Mask2IconDark
                                    }
                                    alt="mask"
                                  />
                                )}
                              </div>
                            </div>
                            <h3>{member.name}</h3>
                            <p>{member.description}</p>
                            {member.link && (
                              <div className={styles.button}>
                                <Button
                                  theme="small secondary"
                                  link={member.link}
                                  external
                                >
                                  <img
                                    src={
                                      isLightTheme
                                        ? LinkedInIcon
                                        : LinkedInIconDark
                                    }
                                    alt="LinkedIn"
                                  />
                                </Button>
                              </div>
                            )}
                          </div>
                        </StyledCard>
                      </div>
                    ))}
                  </div>
                </TabPanel>
              </Tabs>
            </LazyHydrate>
          </div>
        </Row>
      </Container>
    </div>
  )
}

const spring = {
  type: "spring",
  stiffness: 500,
  damping: 30,
}

export default AboutZionodes
