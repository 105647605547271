import React from "react"
import cx from "classnames"
import LazyHydrate from "react-lazy-hydration"
import StyledCard from "../../../ui-kit/StyledCard/StyledCard"
import * as styles from "./InThePressSkeleton.module.sass"

const InThePressSkeleton = ({ isBig = false }) => {
  return (
    <div className={cx(styles.cards, { [styles.cardsBig]: isBig })}>
      {[1, 2, 3, 4, 5].fill(0).map(el => (
        <div key={el} className={styles.card}>
          <StyledCard>
            <div className={styles.inner}>
              <LazyHydrate whenIdle>
                <div className={styles.image}>
                  <div className={cx(styles.icon, styles.animation)}></div>
                </div>
              </LazyHydrate>
              <LazyHydrate ssrOnly>
                <div className={styles.contentBlock}>
                  <div className={styles.nameAndData}>
                    <div className={cx(styles.category, styles.animation)} />
                    <p className={cx(styles.date, styles.animation)} />
                  </div>
                  <h3 className={styles.animation} />
                  <p className={cx(styles.describe, styles.animation)} />
                  <p className={cx(styles.link, styles.animation)} />
                </div>
              </LazyHydrate>
            </div>
          </StyledCard>
        </div>
      ))}
    </div>
  )
}

export default InThePressSkeleton
